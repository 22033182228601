<template lang="pug">
include ../../../configs/template
div.row.mt-6
  div.col-12
    +select('body.status_document', 'listStatuses', 'status', 'nameLang')
  div.col-12
    v-btn(color="success" @click="saveNavigatorCertificateStatus" :loading="isLoading") {{ $t('btn.save') }}

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { checkAccess } from '@/mixins/permissions'
import { STATUSES_STATEMENT_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'

export default {
  name: 'SailorNavigatorCertificateEditStatus',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: {
        status_document: this.statement.status_document
      },
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['statusChoose', 'statusById']),
    listStatuses () {
      let statusesArray = this.statusChoose('CertificateOfNavigator')
      checkAccess('backOffice') && statusesArray.push(this.statusById(STATUSES_STATEMENT_CONSTANTS.SAILOR.QUALIFICATION.CERTIFICATE_OF_NAVIGATOR.DRAFT))
      return statusesArray
    }
  },
  methods: {
    ...mapActions(['updateNavigatorCertificates']),

    async saveNavigatorCertificateStatus () {
      let data = {
        ...this.$route.params,
        body: { ...this.body }
      }
      this.isLoading = true
      const response = await this.updateNavigatorCertificates(data)
      if (SUCCESS_CODE.includes(response.code)) this.$notification.success('editedShipownerLicense')
      this.isLoading = false
    }
  }
}

</script>
